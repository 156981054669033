import { ref, uploadBytes, deleteObject, UploadMetadata, UploadResult, getMetadata, FullMetadata, getDownloadURL } from 'firebase/storage'
import { storage } from '@/services/firebase/instance'
import { DeleteObjectCommand } from '@aws-sdk/client-s3'
import { client } from './s3_config'

const useStorage = () => {
  let _metadata: UploadMetadata = {
    cacheControl: 'public, max-age=3600',
    
  }

  const uploadOnly = (destination: string, file: Blob, medata: UploadMetadata): Promise<UploadResult> => {
    _metadata = Object.assign({}, _metadata, medata)

    const storageRef = ref(storage, destination)
    return uploadBytes(storageRef, file, _metadata)
  }

  const upload = (destination: string, file: Blob, metadata: UploadMetadata): Promise<UploadResult> => {
    const customeMeta = {
      customMetadata:{
        ...metadata
      }
    }
    _metadata = Object.assign({}, _metadata, customeMeta);
  
    const storageRef = ref(storage, destination);
    return new Promise<UploadResult>((resolve, reject) => {
      uploadBytes(storageRef, file, _metadata).then(snapshot => {
        // Get the download URL for the uploaded file
        getDownloadURL(snapshot.ref).then(downloadURL => {
          const uploadResult: UploadResult = {
            downloadURL,
            // You can include other metadata or details if needed
            // For example:
            // name: snapshot.metadata.name,
            // size: snapshot.metadata.size,
            // contentType: snapshot.metadata.contentType,
          };
          resolve(uploadResult);
        }).catch(error => {
          // Handle error getting download URL
          reject(error);
        });
      }).catch(error => {
        // Handle error uploading file
        reject(error);
      });
    });
  };

  const remove = (destination: string): Promise<void> => {
    const storageRef = ref(storage, destination)
    return deleteObject(storageRef)
  }

  const removeS3 = async (destination: string) => {
    const bucketName = 'photowish-images'
    const command = new DeleteObjectCommand({
      "Bucket": bucketName,
      "Key": destination
    })

    return await  client.send(command);
  }

  const getMetaDataFromHttpReference = (url: string): Promise<FullMetadata> => {
    const httpsReference = ref(storage, url)
    return getMetadata(httpsReference)
  }

  return {
    upload,
    remove,
    removeS3,
    getMetaDataFromHttpReference
  }
}

export default useStorage