import { S3Client } from "@aws-sdk/client-s3"

const config = {
  region: 'ap-southeast-1',
  credentials: {
    accessKeyId: 'AKIAW4FR4ZMJTA76ZQXI',
    secretAccessKey: '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'
  }
}

const client = new S3Client(config);

export {
  client
}